import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ConfigAppPage.css";
import NotificationModal from "./NotificationModal";
import { FaUpload } from "react-icons/fa";

const ConfigAppPage = () => {
  const [config, setConfig] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [activeImage, setActiveImage] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/config/app`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setConfig(response.data.payload);
      if (response.data.payload.active_picture) {
        setActiveImage(
          `${process.env.REACT_APP_BACKEND_API}/public/file/${response.data.payload.active_picture}`
        );
      }
    };

    fetchConfig();
  }, [token]);

  const handleUpdateConfig = async () => {
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_API}/config/app`,
      config,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setModalMessage("Config updated successfully");
    setShowModal(true);
    setTimeout(() => setShowModal(false), 5000);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/config/upload_image`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setConfig({ ...config, active_picture: response.data.payload.file_id });
    setActiveImage(URL.createObjectURL(file));
  };

  return (
    <div className="config-container">
      <h2>Config App</h2>
      {config && (
        <div>
          <label>
            Active Picture:
            {activeImage && (
              <img
                src={activeImage}
                alt="Active"
                style={{ width: "100px", height: "100px" }}
              />
            )}
            <div className="file-upload">
              <label htmlFor="file-input" className="custom-file-upload">
                <FaUpload /> Choose File
              </label>
              <input id="file-input" type="file" onChange={handleImageUpload} />
            </div>
          </label>
          <label>
            Is Bot Active:
            <input
              type="checkbox"
              checked={config.is_bot_active}
              onChange={(e) =>
                setConfig({ ...config, is_bot_active: e.target.checked })
              }
            />
          </label>
          <label>
            Active Quiz:
            <input
              type="number"
              value={config.active_quiz}
              onChange={(e) =>
                setConfig({ ...config, active_quiz: e.target.value })
              }
            />
          </label>
          <button onClick={handleUpdateConfig}>Save</button>
        </div>
      )}
      {showModal && (
        <NotificationModal
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ConfigAppPage;
