import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UsersPage.css";
import NotificationModal from "./NotificationModal";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/user/leaderboard`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(response.data.payload);
    };

    fetchUsers();
  }, [token]);

  const handleSendWinnerMessage = async (userId) => {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/user/winner_message`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { user_id: userId },
      }
    );
    setModalMessage("Winner message sent successfully");
    setShowModal(true);
    setTimeout(() => setShowModal(false), 5000);
  };

  const handleConfirmSendWinnerMessage = (user) => {
    setSelectedUser(user);
    setConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModal(false);
    setSelectedUser(null);
  };

  const handleConfirmModalConfirm = () => {
    handleSendWinnerMessage(selectedUser.user_id);
    handleConfirmModalClose();
  };

  return (
    <div className="users-container">
      <h2>Users</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Telegram ID</th>
            <th>Position Title</th>
            <th>Points</th>
            <th>Company</th>
            <th>Telegram Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.user_id}>
              <td>{user.user_id}</td>
              <td>{user.name}</td>
              <td>{user.telegram_id}</td>
              <td>{user.position_title}</td>
              <td>{user.points}</td>
              <td>{user.company}</td>
              <td>{user.telegram_login}</td>
              <td>
                <button onClick={() => handleConfirmSendWinnerMessage(user)}>
                  Send Winner Message
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <NotificationModal
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      )}
      {confirmModal && (
        <div className="confirm-modal">
          <div className="confirm-modal-content">
            <p>
              Are you sure you want to send a winner message to{" "}
              {selectedUser.name}?
            </p>
            <button onClick={handleConfirmModalConfirm}>Yes</button>
            <button onClick={handleConfirmModalClose}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
