import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LoginPage.css";

const LoginPage = ({ setToken }) => {
  const [token, setTokenInput] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/login`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      localStorage.setItem("token", token);
      navigate("/config");
      setToken(token);
    } catch (err) {
      console.log(err);
      setError("Invalid credentials");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <input
          type="text"
          value={token}
          onChange={(e) => setTokenInput(e.target.value)}
          placeholder="Enter token"
        />
        <button onClick={handleLogin}>Login</button>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
